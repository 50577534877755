<button
  class="troi-btn"
  [ngClass]="{ 'troi-btn--gray': disabled, 'troi-btn--outline': outline }"
  [disabled]="disabled"
  type="{{ submit ? 'submit' : 'button' }}"
>
  <span class="troi-btn__text">
    <ng-content></ng-content>
  </span>
</button>
