import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ChildConfig, DomService } from './dom.service';

@Injectable()
export class ModalService {
  public object;

  public events = [];

  public modalClose = new Subject();

  public componentConfig: ChildConfig;

  constructor(private domService: DomService) {}

  private modalElementId = 'modal-container';

  private overlayElementId = 'overlay';

  private modalElementSecondId = 'confirm';

  private overlayElementSecondId = 'confirm-overlay';

  init(
    component: any,
    inputs: Record<string, unknown> = {},
    outputs: Record<string, unknown> = {},
    maxHeight: string = '500px',
    width: string = '800px',
    isSecond: boolean = false,
  ) {
    this.componentConfig = {
      inputs,
      outputs,
    };
    this.domService.appendComponentTo(
      this.getModalElement(isSecond),
      component,
      this.componentConfig,
    );
    document.getElementById(this.getModalElement(isSecond)).className = 'show';
    document.getElementById(this.getModalElement(isSecond)).style.width = width;
    document.getElementById(this.getOverlayElement(isSecond)).className = 'show centered';
    const modalContent = document.getElementsByTagName('troi-modal-content')[
      isSecond ? 1 : 0
    ] as HTMLElement;
    if (modalContent) {
      // modalContent.style.maxHeight = maxHeight;
    }
  }

  public addEvent(event) {
    this.events.push(event);
  }

  destroy(isSecond = false) {
    const iframe = document.getElementsByTagName('iframe')[0] as HTMLIFrameElement;
    if (iframe && iframe.contentDocument !== null) {
      if (iframe.contentWindow.document.getElementById('exportSuccess')) {
        document.location.reload();
      }
      this.modalClose.next(
        iframe.contentWindow.document.getElementById('legacyBookingWasModified'),
      );
    }

    this.domService.removeComponent(this.getModalElement(isSecond));
    document.getElementById(this.getModalElement(isSecond)).className = 'hidden';
    document.getElementById(this.getOverlayElement(isSecond)).className = 'hidden';
  }

  private getModalElement(isSecond) {
    return isSecond ? this.modalElementSecondId : this.modalElementId;
  }

  private getOverlayElement(isSecond) {
    return isSecond ? this.overlayElementSecondId : this.overlayElementId;
  }
}
