import { Injectable } from '@angular/core';
import { ProjectListNetwork } from '../../../../network/project-list.network';
import { BackendResponseInterface } from '../../../../../../core/interfaces/backend.response.interface';
import { IncomingInvoiceModel } from '../../../../models/incoming-invoice.model';
import { LanguagesService } from '../../../../../../core/services/languages.service';

@Injectable()
export class IncomingInvoiceListService {
  public isLoading = true;
  public items: IncomingInvoiceModel[];

  constructor(
    private projectListNetwork: ProjectListNetwork,
    private languageService: LanguagesService,
  ) {}

  public loadList(projectId: string | number): void {
    this.isLoading = true;

    // TODO: TROR-19711 add params or remove at all when are not required
    this.projectListNetwork
      .fetchIncomingInvoices(projectId, { lang: this.languageService.getDataLanguage() })
      .subscribe((result: BackendResponseInterface<IncomingInvoiceModel[]>) => {
        this.items = [...result.data];
        this.isLoading = false;
      });
  }
}
