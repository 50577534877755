import { Injectable } from '@angular/core';
import { ProjectListSettingsService } from '../services/project-list-settings.service';
import { IncomingInvoiceInterface } from '../interfaces/incoming-invoice.interface';
import { IncomingInvoiceModel, IncomingInvoicePrice } from '../models/incoming-invoice.model';
import { ProjectListFactory } from './project-list.factory';

@Injectable()
export class IncomingInvoiceFactory extends ProjectListFactory {
  constructor(protected projectListSettingsService: ProjectListSettingsService) {
    super(projectListSettingsService);
  }

  public buildIncomingInvoice = (
    incomingInvoice: IncomingInvoiceInterface,
  ): IncomingInvoiceModel => {
    return new IncomingInvoiceModel(
      incomingInvoice.date,
      incomingInvoice.documentNumber,
      incomingInvoice.debitAccountName,
      incomingInvoice.debitAccountNumber,
      incomingInvoice.creditAccountNumber,
      incomingInvoice.creditAccountName,
      incomingInvoice.description,
      new IncomingInvoicePrice(
        this.projectListSettingsService.settings.systemCurrency,
        this.parsePrice(
          incomingInvoice.amountNet,
          this.projectListSettingsService.settings.systemCurrency,
          this.projectListSettingsService.settings,
        ),
      ),
    );
  };

  public buildIncomingInvoices(incomingInvoices: IncomingInvoiceInterface[]) {
    return incomingInvoices.map(this.buildIncomingInvoice);
  }
}
