import { Money } from '../../../shared/troi-money/money';
import { Currency } from '../../../shared/troi-money/currency';

export class IncomingInvoicePrice {
  public constructor(public currency: Currency, public price: Money) {}
}

export class IncomingInvoiceModel {
  constructor(
    public date: number,
    public documentNumber: string,
    public debitAccountName: string,
    public debitAccountNumber: string,
    public creditAccountNumber: string,
    public creditAccountName: string,
    public description: string,
    public amountNet: IncomingInvoicePrice,
  ) {}
}
