<div class="project-list">
  <header-notification></header-notification>
  <div class="project-list__header">
    <troi-data-listing-filters
      [firstDropdown]="filtersService.generateFirstDropdown()"
      (firstDropdownChanged)="clientChanged($event)"
      [secondDropdown]="filtersService.customersDropdown"
      (secondDropdownChanged)="filterChanged($event)"
      [reloadSecondDropdownOptions]="filtersService.reloadCustomersData"
      [resetSecondDropdown]="filtersService.resetQuickFilters"
      [thirdDropdown]="filtersService.thirdDropdown"
      (thirdDropdownChanged)="filterChanged($event)"
      [reloadThirdDropdownOptions]="filtersService.reloadProjectStatusesData"
      [resetThirdDropdown]="filtersService.resetQuickFilters"
      [filters]="filtersService.actualFilters"
      (filtersChanged)="filterChanged($event)"
      (filtersReset)="filterReset()"
      [columnList]="columnsService.getColumnList()"
      (columnsChanged)="columnsService.saveColumnList($event)"
      [client]="filtersService.getSelectedClientId()"
      [lang]="languagesService.getLanguage()"
      [dateFormat]="projectListSettingsService.settings?.settings.dateFormat"
      [limitDate]="false"
      header="ProjectList.labels.header"
      [selectedFilterSetId]="filtersService.selectedFilterSetId"
      [filterSets]="filtersService.filterSets"
      [areFiltersEdited]="filtersService.areFiltersEdited"
      (filterSetSelected)="filterSetSelected($event)"
      (filterSetDeleted)="filterSetDeleted($event)"
      (filterSetAsDefault)="filterSetAsDefault($event)"
      (filterSetOpenSave)="openFilterSetSaveModal()"
      [reloadFilterChips]="filtersService.reloadFilterChips"
    >
      <list-top-bar class="body__actions"></list-top-bar>
    </troi-data-listing-filters>
  </div>
  <div class="project-list__body">
    <router-outlet></router-outlet>
    <div class="body__loading-wrapper" *ngIf="!treeViewListService.isContentReady()">
      <div class="body__loading">
        <troi-loading
          [rows]="3"
          [columns]="[
            '25px',
            '20%',
            '20px',
            '5%',
            '20px',
            '5%',
            '20px',
            '5%',
            '20px',
            '5%',
            '20px',
            '5%',
            '20px',
            '5%',
            '20px',
            '5%',
            '20px',
            '5%',
            '20px',
            '5%',
            '20px',
            '5%',
            '20px',
            '5%',
            '20px',
            '8%',
            '30px'
          ]"
        >
        </troi-loading>
      </div>
    </div>
  </div>
  <div class="project-list__footer">
    <troi-footer
      (currencyChanged)="projectListSettingsService.saveActiveCurrency($event)"
      [settings]="projectListSettingsService.settings"
      [disabledCurrency]="true"
      [settingsEnabled]="true"
      [loggingWorkingTimeEnabled]="
        projectListSettingsService.settings?.settings.isLoggingWorkingTimeEnabled
      "
    ></troi-footer>
    <div id="overlay" class="hidden">
      <div id="modal-container" class="hidden"></div>
    </div>
    <div id="confirm-overlay" class="hidden">
      <div id="confirm" class="hidden"></div>
    </div>
  </div>
</div>
